.reactjrupaecobrick{
    background-color: rgb(126, 126, 126);
}

.buttonapply{
    background-color: #B10557; 
    border: none;
    color: white;
    padding: 5px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
}