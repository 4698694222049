
  .brcikmurshidabad{
    
    background-color: rgb(0, 0, 0);
    padding: 0%;
    color: white;
    text-decoration-color:  rgb(224, 78, 78);
}

.colors1{
  color: rgb(0, 52, 87);
}


.tilesberhampore{
  height:  420px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-image: url(https://i.postimg.cc/0yQpHyDf/mainbanner.jpg);
  background-attachment: fixed;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}




.Cataloguebutton{
  background-color: #015f6d; 
  border: none;
  color: white;
  padding: 7px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}

.colorcall{
  background-color: #0d6f98; 
  border: none;
  color: white;
  padding: 7px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}

.justify{
  text-align: justify;
  text-justify: inter-word;
}